// extracted by mini-css-extract-plugin
export var aboutMeasure = "how-to-measure-module--about-measure--cl0GB";
export var aboutMeasureText = "how-to-measure-module--about-measure-text---PzP1";
export var heading = "how-to-measure-module--heading--ujmzS";
export var hide = "how-to-measure-module--hide--NVAFA";
export var icon = "how-to-measure-module--icon--dj8rg";
export var mainSection = "how-to-measure-module--main-section--Rtak3";
export var measure1 = "how-to-measure-module--measure-1--8C0TP";
export var measure2 = "how-to-measure-module--measure-2--eMaxo";
export var measure3 = "how-to-measure-module--measure-3--P98lF";
export var measureContainer = "how-to-measure-module--measure-container--WRG6m";
export var measureDescription = "how-to-measure-module--measure-description--Bigk8";
export var measureDot = "how-to-measure-module--measure-dot--5NQQN";
export var measureFigure = "how-to-measure-module--measure-figure--APzuu";
export var measureHeading = "how-to-measure-module--measure-heading--UJxGj";
export var measureImg = "how-to-measure-module--measure-img--kteGC";
export var measureIntro = "how-to-measure-module--measure-intro--yowgG";
export var measureLast = "how-to-measure-module--measure-last--ykXFN";
export var measurePage = "how-to-measure-module--measure-page--R-uPt";
export var measureWrapper = "how-to-measure-module--measure-wrapper--g8+i2";
export var sizesEur = "how-to-measure-module--sizes-eur--fmK9W";
export var sizesImage = "how-to-measure-module--sizes-image--HIgn-";
export var sizesInt = "how-to-measure-module--sizes-int--TI4Ak";
export var sizesWrapper = "how-to-measure-module--sizes-wrapper--r37Gy";
export var tableWrapper = "how-to-measure-module--table-wrapper--hUrkC";
export var tapeMeasure = "how-to-measure-module--tape-measure--7ZGTf";
export var tapeSection = "how-to-measure-module--tape-section--LxU41";
export var title = "how-to-measure-module--title--MqloX";
export var upperMeasures = "how-to-measure-module--upper-measures--AnWMU";
export var visible = "how-to-measure-module--visible--nlvw5";